<template>
  <div>
    <base-modal
      :showing="visible && !detailPacket.visible"
      size="max-w-6xl"
      @opened="onOpened"
      @close="onClose"
    >
      <base-card
        :with-style="false"
        title="Detail Pengiriman Barang"
        description="Data Pengiriman Barang"
      >
        <div class="space-y-6">
          <div class="grid gap-6 border-t pt-5 sm:grid-cols-3">
            <div>
              <dt class="text-xs text-gray-700">No Pengiriman</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockMovement.data.attributes.code }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">No Pembelian</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockMovement.data.attributes.order_code }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">No Kantor Asal</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockMovement.data.attributes.origin_office_code }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">Tanggal</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockMovement.data.attributes.createdAt | formatDate }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">Total Barang</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                <p v-if="stockMovement.data.attributes.product_summaries">
                  {{
                    Object.keys(stockMovement.data.attributes.product_summaries)
                      .length | toCurrency
                  }}
                </p>
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">Status</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                <base-badge :color="statusColor">{{ statusText }}</base-badge>
              </dd>
            </div>
            <div v-if="columns.includes('validation_status')">
              <dt class="text-xs text-gray-700">Status Validasi</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                <base-badge
                  :color="
                    stockMovement.data.attributes.is_valid_for_shipment
                      ? 'green'
                      : 'yellow'
                  "
                  >{{
                    stockMovement.data.attributes.is_valid_for_shipment
                      ? 'Valid'
                      : 'Draft'
                  }}</base-badge
                >
              </dd>
            </div>
            <div v-if="withFirstPacketProduct" class="col-span-full">
              <dt class="text-xs text-gray-700">Barang</dt>
              <dd class="mt-1">
                <datatable
                  :columns="[
                    { id: 'code', name: 'Kode' },
                    { id: 'name', name: 'Nama' },
                    { id: 'qty', name: 'Jumlah' },
                    { id: 'received_qty', name: 'Jumlah Diterima' },
                  ]"
                >
                  <template #tbody="{ classes }">
                    <tr
                      v-for="(product, i) in firstPacketProduct"
                      :key="i"
                      :class="classes.tr"
                    >
                      <td :class="classes.td">
                        <div class="flex items-center gap-2">
                          <Icon
                            v-if="canScanSupplier && checkProductHasQr(product)"
                            icon="heroicons:qr-code"
                            class="h-4 w-4"
                          />
                          <p class="font-bold text-gray-900">
                            {{ product.product_code }}
                            <span v-if="product.product_type === 'free'"
                              >(Promo)</span
                            >
                          </p>
                        </div>
                      </td>
                      <td :class="classes.td">{{ product.product_name }}</td>
                      <td :class="classes.td">
                        {{ product.shipped_product_qty | toCurrency }}
                      </td>
                      <td :class="classes.td">
                        <span v-if="checkProductHasQr(product)">{{
                          product.received_product_qty | toCurrency
                        }}</span>
                        <input
                          v-else
                          type="text"
                          class="border-0 p-0 text-sm placeholder-gray-300 focus:ring-0"
                          placeholder="Masukkan Jumlah"
                          :value="product.received_product_qty"
                          @change="(e) => onChangeProductQty(e, i, product)"
                        />
                      </td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>
            <div v-else class="col-span-full">
              <dt class="text-xs text-gray-700">Daftar Koli</dt>
              <dd class="mt-1">
                <datatable
                  :columns="[
                    { id: 'code', name: 'Kode' },
                    { id: 'name', name: 'Nama' },
                    { id: 'qty', name: 'Jumlah' },
                  ]"
                >
                  <template #tbody="{ classes }">
                    <tr
                      v-for="(packet, i) in stockMovement.data.attributes
                        .packets"
                      :key="i"
                      :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
                      @click="onClickDetailPacket(packet)"
                    >
                      <td :class="classes.td">
                        <p class="font-bold text-gray-900">
                          {{ packet.code }}
                        </p>
                      </td>
                      <td :class="classes.td">{{ packet.name }}</td>
                      <td :class="classes.td">
                        {{ getPacketQty(packet) | toCurrency }}
                      </td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>
            <div v-if="withBatch" class="col-span-full">
              <dt class="text-xs text-gray-700">Batch</dt>
              <dd class="mt-1">
                <datatable
                  :columns="[
                    { id: 'code', name: 'No Batch' },
                    { id: 'name', name: 'Barang' },
                    { id: 'expire', name: 'Kadaluarsa' },
                    { id: 'qty', name: 'Jumlah' },
                  ]"
                >
                  <template #tbody="{ classes }">
                    <tr
                      v-for="(batch, i) in batches"
                      :key="i"
                      :class="classes.tr"
                    >
                      <td :class="[classes.td]">
                        <p class="font-bold text-gray-900">
                          {{ batch.attributes.no_batch }}
                        </p>
                      </td>
                      <td :class="classes.td">
                        {{ batch.attributes.product_code }}
                        <span v-if="batch.attributes.product_type === 'free'"
                          >(Promo)</span
                        >
                      </td>
                      <td :class="classes.td">
                        {{ batch.attributes.expires_at | formatDate }}
                      </td>
                      <td :class="classes.td">
                        {{ batch.attributes.product_qty | toCurrency }}
                      </td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>
          </div>

          <div v-if="actionVisible" class="mt-6 flex justify-end gap-2">
            <template
              v-if="
                !stockMovement.data.attributes.is_valid_for_shipment &&
                withValidation
              "
            >
              <base-button color="danger" @click="onDelete">
                <Icon icon="heroicons:trash-solid" class="h-4 w-4 text-white" />
                Hapus
              </base-button>
              <base-button @click="onValidate">
                <Icon
                  icon="heroicons:shield-check-solid"
                  class="h-4 w-4 text-white"
                />
                Validasi
              </base-button>
            </template>
            <template v-if="withActions">
              <base-button
                v-if="!stockMovement.data.attributes.is_shipped"
                @click="onSend"
              >
                <Icon icon="heroicons:truck-solid" class="h-4 w-4 text-white" />
                Kirim
              </base-button>
              <base-button
                v-if="
                  stockMovement.data.attributes.is_shipped &&
                  !stockMovement.data.attributes.is_received
                "
                @click="onReceive"
              >
                <Icon
                  icon="heroicons:archive-box-arrow-down-solid"
                  class="h-4 w-4 text-white"
                />
                <span v-if="canScanSupplier">
                  {{
                    hasReceivingRemainder
                      ? 'Terima dengan Komplain'
                      : 'Terima Semua'
                  }}
                </span>
                <span v-else>Terima</span>
              </base-button>
            </template>
          </div>
        </div>
      </base-card>

      <teleport to="body">
        <loading v-if="loading" />
      </teleport>
    </base-modal>

    <view-packet-modal
      :visible="detailPacket.visible"
      :packet="detailPacket.packet"
      @close="onCloseDetailPacket"
      @complained="onComplained"
    />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import { requestMixin } from '@/mixins/request/request';
import ViewPacketModal from './view-packet-modal.vue';
import Teleport from 'vue2-teleport';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    Teleport,
    ViewPacketModal,
  },
  props: {
    visible: Boolean,
    stockMovementId: String,
    withFirstPacketProduct: Boolean,
    withBatch: Boolean,
    withActions: Boolean,
    withScanSupplier: Boolean,
    withValidation: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => ['validation_status'],
    },
  },
  emits: ['close'],
  data() {
    return {
      detailPacket: {
        visible: false,
        packet: {},
      },
      loading: false,
      reload: true,
      stockMovement: {
        data: {
          attributes: {},
          relationships: {
            'product-batches': {
              data: null,
            },
          },
        },
        included: [],
      },
      productBoxes: [],
    };
  },
  computed: {
    actionVisible() {
      if (this.readonly) {
        return false;
      }

      if (!this.stockMovement.data.attributes.is_valid_for_shipment) {
        return true;
      }

      if (!this.withActions) {
        return false;
      }

      return (
        !this.stockMovement.data.attributes.is_shipped ||
        !this.stockMovement.data.attributes.is_received
      );
    },
    batches() {
      if (!this.withBatch) {
        return [];
      }

      if (!this.stockMovement.data.relationships['product-batches'].data) {
        return [];
      }

      return this.getIncludedByType(this.stockMovement, 'product-batches');
    },
    canScanSupplier() {
      return (
        this.withScanSupplier &&
        this.stockMovement.data.attributes.is_shipped &&
        !this.stockMovement.data.attributes.is_received
      );
    },
    firstPacketProduct() {
      if (!this.stockMovement.data.attributes.packets) {
        return [];
      }

      return this.stockMovement.data.attributes.packets[0].products;
    },
    hasReceivingRemainder() {
      return this.firstPacketProduct.some(
        (product) =>
          product.received_product_qty !== product.shipped_product_qty
      );
    },
    statusColor() {
      if (!this.stockMovement.data.attributes.is_valid_for_shipment) {
        return 'yellow';
      }

      if (!this.stockMovement.data.attributes.is_shipped) {
        return 'red';
      }

      if (!this.stockMovement.data.attributes.is_received) {
        return 'yellow';
      }

      return 'green';
    },
    statusText() {
      if (!this.stockMovement.data.attributes.is_valid_for_shipment) {
        return 'Belum Dikemas';
      }

      if (!this.stockMovement.data.attributes.is_shipped) {
        return 'Belum Dikirim';
      }

      if (!this.stockMovement.data.attributes.is_received) {
        return 'Belum Diterima';
      }

      return 'Diterima';
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    checkProductHasQr(product) {
      if (product.product_qrcode && product.product_qrcode_active) {
        return true;
      }

      if (product.qr_start && product.qr_end) {
        return true;
      }

      return this.productBoxes.some(
        (box) => box.product.uuid === product.product_id
      );
    },
    incrementProductReceivedQty(id, amount = 1) {
      const productIndex = this.firstPacketProduct.findIndex(
        (packetProduct) => packetProduct.uuid === id
      );

      this.stockMovement.data.attributes.packets[0].products[
        productIndex
      ].received_product_qty += amount;
    },
    getPacketByQrCode(qrcode) {
      return this.firstPacketProduct.find(
        (product) =>
          product.product_qrcode === qrcode && product.product_qrcode_active
      );
    },
    getProductBoxByQrCode(qrcode) {
      return this.productBoxes.find((box) => box.qrcode === qrcode);
    },
    getPacketById(id) {
      return this.firstPacketProduct.find((product) => product.uuid === id);
    },
    getPacketByProductId(productId) {
      return this.firstPacketProduct.find(
        (product) => product.product_id === productId
      );
    },
    getPacketQty(packet) {
      return packet.products.reduce(
        (total, item) => total + item.shipped_product_qty,
        0
      );
    },
    async loadStockMovement() {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/stock-movements/${this.stockMovementId}`,
        {
          params: {
            'fields[stock-movements]':
              'code,order_code,origin_office_code,createdAt,product_summaries,is_shipped,is_received,is_valid_for_shipment,packets,product-batches,order',
            'fields[orders]': 'origin_code',
            include: this.withBatch ? 'product-batches,order' : 'order',
          },
        }
      );

      if (!err) {
        this.stockMovement = res;

        if (this.canScanSupplier) {
          await this.loadOrderProductBoxes();

          document.addEventListener('barcode-scan', this.onScanSupplier);
        }
      }

      this.loading = false;
    },
    async loadOrderProductBoxes() {
      const [res, err] = await this.request(
        `/api/v1/orders/${this.stockMovement.data.relationships.order.data.id}/-actions/product-boxes`
      );

      if (!err) {
        this.productBoxes = res;
      }
    },
    onOpened() {
      if (this.reload) {
        this.loadStockMovement();
      }
    },
    onChangeProductQty(e, i, product) {
      const qty = Number(e.target.value);

      if (isNaN(qty) || qty < 0 || qty > product.shipped_product_qty) {
        e.target.value = product.received_product_qty;
      } else {
        this.stockMovement.data.attributes.packets[0].products[
          i
        ].received_product_qty = qty;
      }
    },
    onClose() {
      document.removeEventListener('barcode-scan', this.onScanSupplier);

      this.$emit('close');
      this.reload = true;
    },
    onClickDetailPacket(packet) {
      this.detailPacket.visible = true;
      this.detailPacket.packet = packet;

      this.reload = false;
    },
    onCloseDetailPacket() {
      this.detailPacket.visible = false;
    },
    async onDelete() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/stock-movements/${this.stockMovement.data.id}`,
        {
          method: 'delete',
        }
      );

      if (!err) {
        this.$emit('deleted');
      }

      this.loading = false;
    },
    onComplained() {
      this.detailPacket.visible = false;
      this.reload = true;

      this.$emit('complained');
    },
    async onReceive() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/stock-movements/${this.stockMovement.data.id}/-actions/recieve`,
        {
          method: 'patch',
          data: this.canScanSupplier ? {
            received_products: this.firstPacketProduct.map(product => ({
              movement_detail_id: product.uuid,
              qty: product.received_product_qty
            }))
          } : {
          },
        }
      );

      if (!err) {
        this.$emit('received');

        if (this.withScanSupplier) {
          document.removeEventListener('barcode-scan', this.onScanSupplier);
        }
      }

      this.loading = false;
    },
    async onSend() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/stock-movements/${this.stockMovement.data.id}/-actions/send`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'stock-movements',
              id: this.stockMovement.data.id,
            },
          },
        }
      );

      if (!err) {
        this.$emit('sent');
      }

      this.loading = false;
    },
    async onValidate() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/stock-movements/${this.stockMovement.data.id}/-actions/validate-shipment`,
        {
          method: 'patch',
        }
      );

      if (!err) {
        this.$emit('validated');
      }

      this.loading = false;
    },
    async onScanSupplier(e) {
      this.loading = true;

      const { text: qrcode } = e.detail;
      const packet = this.getPacketByQrCode(qrcode);
      const box = this.getProductBoxByQrCode(qrcode);

      if (packet) {
        if (packet.received_product_qty >= packet.shipped_product_qty) {
          this.createAlert({
            status: 'error',
            data: 'Produk diterima melebihi jumlah dikirim',
          });
        } else {
          this.incrementProductReceivedQty(packet.uuid);
        }
      } else if (box) {
        const packetBox = this.getPacketByProductId(box.product.uuid);
        const amount = box.product_count;

        if (
          packetBox.received_product_qty + amount >
          packetBox.shipped_product_qty
        ) {
          this.createAlert({
            status: 'error',
            data: 'Produk diterima melebihi jumlah dikirim',
          });
        } else {
          this.incrementProductReceivedQty(packetBox.uuid, amount);
        }
      } else {
        const [res, err] = await this.request(
          `/api/v1/stock-movements/${this.stockMovement.data.id}/-actions/scan-supplier-product`,
          {
            method: 'post',
            data: {
              qrcode,
            },
          }
        );

        if (err) {
          this.createAlert({
            status: 'error',
            data: this.getRequestErrorMessage(err),
          });
        } else {
          const packetProduct = this.getPacketById(res.uuid);

          if (
            packetProduct.received_product_qty <
            packetProduct.shipped_product_qty
          ) {
            this.incrementProductReceivedQty(res.uuid);
          }
        }
      }

      this.loading = false;
    },
  },
  destroyed() {
    document.removeEventListener('barcode-scan', this.onScanSupplier);
  },
};
</script>
